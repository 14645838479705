export default {
  // Endpoints
  loginEndpoint: process.env.NODE_ENV === 'production' ? 'pamis/identity/v1/Login' : 'https://apitestlocal.niso.dev/pamis/identity/v1/Login',
  getUserDataEndpoint: 'https://sf.eldor.com.tr/AuthService/api/Auth/GetUserInfo',
  moduleEndpoint: '/admin/clientmodules',
  logincompanyEndpoint: '/admin/logincompany',

  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',

  getUsers: '/admin/f/users',
  getUsersByRole: '/admin/f/usersbyrole',
  addUser: '/admin/createuser',

  getCategories: '/lms/admin/course/category',
  addCategory: '/lms/admin/course/category',

  getCourses: '/lms/admin/course/getallcourses',
  getCourse: '/lms/admin/course/getcoursedetail',
  addCourse: '/lms/admin/course/create',
  updateCourse: '/lms/admin/course/updateCourse',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageClientAuthTokenKeyName: 'clientAuthToken',
  storageClientLogoKeyName: 'clientLogo',

  storageRefreshTokenKeyName: 'refreshToken',
}
